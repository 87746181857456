interface RuntimeConfig {
  [key: string]: string | boolean | number;
}

declare global {
  interface Window {
    feAppPrbOutlookRuntimeConfig: RuntimeConfig;
    BUILD_DATE: string;
  }
}

// Fetch the runtime config with several attempts
function getRuntimeConfig(attempt = 1): Promise<RuntimeConfig> {
  const maxAttempts = 3;
  const runtimeConfigUrl = '/config.json';

  return fetch(runtimeConfigUrl).then((res) => {
    if (res.ok) {
      return res.json();
    }

    if (attempt < maxAttempts) {
      console.warn('Failed to fetch runtime config, running another attempt...');
      return getRuntimeConfig(attempt + 1);
    }

    console.error('Failed to fetch runtimme configuration!');
    console.error(res.statusText);
    return {};
  });
}

// Alternatively fetch the runtime config from process.env
function getRuntimeConfigFromEnv(): Promise<RuntimeConfig> {
  return Promise.resolve({
    DEPLOY_ENVIRONMENT: ENV.DEPLOY_ENVIRONMENT || '',
    PRB_VERSION_DATE: ENV.PRB_VERSION_DATE || '',
    SHOW_ENV_BANNER: ENV.SHOW_ENV_BANNER || '',
    ENV_BANNER_COLOR: ENV.ENV_BANNER_COLOR || '',
    PRB_URL: ENV.PRB_URL || '',
    API_URL: ENV.API_URL || '',
    ELEARNING_URL: ENV.ELEARNING_URL || '',
    LEARNING_URL: ENV.LEARNING_URL || '',
    GRAPH_CLIENT_ID: ENV.GRAPH_CLIENT_ID || '',
    SENTRY_URL: ENV.SENTRY_URL || '',
  });
}

// Fetch configuration
const runtimeConfigPromise =
  ENV.USE_RUNTIME_CONFIG === 'true' ? getRuntimeConfig() : getRuntimeConfigFromEnv();

runtimeConfigPromise.then((runtimeConfig) => {
  // Set the runtime config for global usage
  window.feAppPrbOutlookRuntimeConfig = runtimeConfig;

  // Bootstrap the app
  import('./bootstrap');
});

export {};
